/*Box sizing (the nuclear option) */
*,
*:before,
*:after {
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Fix font rendering defaults */
html,
button {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 100%;
  line-height: 1.25;
  color: #121212;
  background-color: #fafafa;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
input,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
}

/* IE fixes for HTML5 elements */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

strong,
b,
mark {
  font-weight: 600;
  font-style: inherit;
}

em,
i,
cite,
q,
address,
dfn,
var {
  font-style: italic;
  font-weight: inherit;
}

a,
u,
ins {
  text-decoration: none;
}

pre,
code,
samp,
kbd {
  font-family: monospace;
}

iframe {
  width: 100%;
}

img {
  max-width: 100%;
  height: auto;
  border: none;
}

input,
select,
textarea {
  font: inherit;
}

button {
  cursor: pointer;
}

#cc-main {
  /** Change font **/
  --cc-font-family: sans-serif;

  /** Change button primary color to black **/
  --cc-btn-primary-bg: #1556ff;
  --cc-btn-primary-border-color: #1556ff;
  --cc-btn-primary-hover-bg: #4176ff;
  --cc-btn-primary-hover-border-color: #4176ff;

  /** Also make toggles the same color as the button **/
  --cc-toggle-on-bg: var(--cc-btn-primary-bg);

  /** Make the buttons a bit rounder **/
  --cc-btn-border-radius: 0;
  --cc-modal-border-radius: 0.25rem;
  --cc-modal-margin: 2rem;
}
